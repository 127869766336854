<template>
  <div id="app">
    <VueHello/>
  </div>
</template>

<script>
import VueHello from './components/Hello';

export default {
  name: 'Example',
  components: {
    VueHello,
  },
};
</script>

<style>

</style>
